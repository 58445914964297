@use "@angular/material" as mat;
@import "./theme/lib/style/color";

@include mat.core();

$app-primary: mat.define-palette($md-mcgpalette0, 500);
$app-accent: mat.define-palette(mat.$teal-palette, A200);
$app-warn: mat.define-palette(mat.$red-palette);

//@include mat.button-color($custom-theme);

$typography: mat.define-typography-config(
  $font-family: "Archivo",
);

$theme: mat.define-light-theme(
    (
      color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      ),
      typography: $typography,
    )
);

@include mat.all-component-themes($theme);
.mat-drawer.mat-drawer-side {
  z-index: 1!important;
  background-color: #FBFBFB !important;
}

html, body {
  height: 100%;
}

* {
  font-family: 'Archivo'!important;
  --mdc-dialog-container-shape: 12px;
}

svg {
  vertical-align: top;
}

.error-message {
  color: #ed6c68;
}

label {
  color: #727688;
  font-size: 14px;
}

.disabled_none {
  pointer-events: none !important;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf');
}
.font-mont {
  font-family: 'Montserrat' !important;
}
.border-top{
  border-top-color: #EAEBEF !important;
}


.light-gray {
  color: #727688;
  font-size: 14px;
}

.pointer{
  cursor: pointer;
}
.back-button{
  width: fit-content;
  cursor: pointer;
  margin-bottom: 40px;
  mat-icon{
    width: 8px;
    margin-right: 12px;
  }
  > div{
    font-size: 14px;
    font-weight: 700;
    color: #727688;
  }
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.mdc-checkbox .mdc-checkbox_native-control:enabled:checked~.mdc-checkbox_background{
  background-color: #ed6c68 !important;
  border-color: #ed6c68 !important;
}
.mdc-checkbox .mdc-checkbox_native-control:enabled~.mdc-checkboxbackground .mdc-checkbox_checkmark{
  color: #fff !important;
}
.mat-mdc-tab-body-content{
  overflow: hidden !important;
}
.tabs-card .mat-mdc-form-field-infix{
  width: 200px;
}
.tabs-card .first_dropdown .mat-mdc-form-field-infix{
  width: 170px;
}

.card-shadow{
  box-shadow: 4px 8px 24px 0px #304DBB2E !important;
}

textarea {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border: none;
  border-bottom: 1px solid #EAEBEF;
  border-radius: 8px;
  padding: 14px 16px;
  transition: 0.1s ease-out;
  margin: 0;
  line-height: 1;
}

.mat-mdc-select-panel {
  margin-bottom: 10px;
}

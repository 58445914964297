/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
  50 : #fdeded,
  100 : #fad3d2,
  200 : #f6b6b4,
  300 : #f29895,
  400 : #f0827f,
  500 : #ed6c68,
  600 : #eb6460,
  700 : #e85955,
  800 : #e54f4b,
  900 : #e03d3a,
  A100 : #ffffff,
  A200 : #fff8f8,
  A400 : #ffc6c5,
  A700 : #ffadac,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
